import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-project-layout",
  templateUrl: "./project-layout.component.html",
  styleUrls: ["./project-layout.component.scss"]
})
export class ProjectLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
