import { Observable, Subject } from 'rxjs';
import { UtilsService } from './utils.service';
import { Injectable } from '@angular/core';

import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private logger: NGXLogger,
    public http: HttpClient,
    public global: GlobalService,
    private utils: UtilsService
  ) {}

  getHeaders() {
    let headers = new HttpHeaders({
      authorization: this.global.getToken(),
    });
    return headers;
  }

  list(rota) {

    return this.http.get<any>(this.global.url + '/data/' + rota, {
      headers: this.getHeaders(),
    });
  }

  getItem(tb, id, id2) {
    //Busca um item
    let rota = `item/${tb}/${id}/${id2}`;
    return this.http.get<any>(this.global.url + '/data/' + rota, {
      headers: this.getHeaders(),
    });
  }

  listLike(rota, obj) {
    return this.http.post<any>(this.global.url + '/data/' + rota, obj, {
      headers: this.getHeaders(),
    });
  }

  add(rota, obj) {
    return this.http.post<any>(this.global.url + '/data/' + rota, obj, {
      headers: this.getHeaders(),
    });
  }

  storage(obj) {
    return this.http.post<any>(this.global.url + '/data/storage', obj, {
      headers: this.getHeaders(),
    });
  }

  update(rota, id, campo, obj) {
    return this.http.put<any>(this.global.url + '/data/item/' + rota + '/' + id + '/' + campo, obj, {
      headers: this.getHeaders(),
    });
  }

  favoritos(dados) {
    return this.http.put<any>(this.global.url + '/functions/favoritos', dados, {
      headers: this.getHeaders(),
    });
  }

  getTodosLocais() {
    return this.http.get<any>(this.global.url + '/functions/todosLocais');
  }

  isAuthenticated() {
    return this.http.post<any>(this.global.url + '/auth/isAuthenticated', {
      token: this.global.getToken(),
    });
  }

  dependentes(dados) {
    return this.http.post<any>(this.global.url + '/functions/dependentes', dados, { headers: this.getHeaders() });
  }

  del(rota, id, campo, hard) {
    return this.http.delete<any>(this.global.url + '/data/item/' + rota + '/' + id + '/' + campo + '/' + hard, {
      headers: this.getHeaders(),
    });
  }

  auth(rota, obj) {
    return this.http.post<any>(this.global.url + '/auth/user/' + rota, obj, {
      headers: this.getHeaders(),
    });
  }

  newUser(rota, obj) {
    return this.http.post<any>(this.global.url + '/auth/user/' + rota, obj);
  }

  recuperarSenha(rota, obj) {
    return this.http.post<any>(this.global.url + '/auth/reset/password/' + rota, obj);
  }

  validarSenha(rota, obj) {
    return this.http.post<any>(this.global.url + '/auth/user/pass/' + rota, obj, { headers: this.getHeaders() });
  }

  updateSenha(rota, obj) {
    // Para a realização de autalização da senha é necessário que o objeto passado venha com 'pass:'depois a nova senha informada
    return this.http.put<any>(this.global.url + '/auth/update/password/' + rota, obj, { headers: this.getHeaders() });
  }

  updateToken(rota, obj) {
    // Para a realização de autalização da senha é necessário que o objeto passado venha com 'pass:'depois a nova senha informada
    return this.http.put<any>(this.global.url + '/auth/update/user/' + rota, obj, { headers: this.getHeaders() });
  }

  login(rota, obj) {
    return this.http.post<any>(this.global.url + '/auth/login/user/' + rota, obj);
  }

  createAccount(value): Observable<any> {
    var subject = new Subject<any>();

    this.newUser('G009/EMAIL/PASS', value).subscribe(
      data => {
        subject.next({ value: true, id: data });
      },
      error => {
        this.utils.msgError(error);
        subject.next({ value: false, id: error });
      }
    );
    error => {
      this.utils.msgError('Erro ao cadastrar!');
      subject.next({ value: false, id: error });
    };

    return subject.asObservable();
  }

  push(tb: string, value, comparador?: string[], alert?): Observable<any> {
    var subject = new Subject<any>();
    if (comparador != null) {
      for (let i = 0; i < comparador.length; i++) {
        comparador[i] = comparador[i] + '=' + value[comparador[i]];
      }
      let where = comparador.join(',');
      let rota = tb + '/*/*/' + where;

      this.list(rota).subscribe(
        data => {
          if (data.data.length == 0) {
            this.add(tb, value).subscribe(
              data => {
                if (alert != false) {
                  this.utils.msgSuccess('Cadastro feito com sucesso!');
                }

                subject.next({ value: true, id: data });

                this.gravaLog(tb, data.id, this.global.getUser().IDG009, 'create', null, JSON.stringify(value));
              },
              error => {
                this.utils.msgError('Erro ao cadastrar!');
                subject.next({ value: false, id: data });
              }
            );
          } else if (data.data.length >= 1) {
            let item = data.data[0];
            if (item.DELETED == 'S') {
              let id = 'ID' + tb;
              value.DELETED = 'N';
              this.update(tb, item[id], id, value).subscribe(
                data => {
                  if (alert != false) {
                    this.utils.msgSuccess('Cadastro feito com sucesso!');
                  }
                  subject.next({ value: true, id: data });

                  this.gravaLog(tb, item[id], this.global.getUser().IDG009, 'create');
                },
                error => {
                  this.utils.msgError('Erro ao cadastrar!');
                  subject.next({ value: false, id: data });
                }
              );
            } else if (item.DELETED == 'N') {
              this.utils.msgError('Item já cadastrado!');
              subject.next({ value: false, id: data });
            }
          }
        },
        error => {
          this.utils.msgError('Erro ao cadastrar!');
          subject.next({ value: false, id: error });
        }
      );
    } else {
      this.add(tb, value).subscribe(
        data => {
          if (alert != false) {
            this.utils.msgSuccess('Cadastro feito com sucesso!');
          }
          subject.next({ value: true, id: data });

          this.gravaLog(tb, data.id, this.global.getUser().IDG009, 'create');
        },
        error => {
          this.utils.msgError('Erro ao cadastrar!');

          subject.next({ value: false, id: error });
        }
      );
    }

    return subject.asObservable();
  }

  pushWithoutComparadorAndAlert(tb: string, value): Observable<any> {
    var subject = new Subject<any>();

    this.add(tb, value).subscribe(
      data => {
        subject.next({ value: true, id: data });

        this.gravaLog(tb, data.id, this.global.getUser().IDG009, 'create');
      },
      error => {
        this.utils.msgError('Erro ao cadastrar!');
        subject.next({ value: false, id: error });
      }
    );

    return subject.asObservable();
  }

  verificaPermissoes(component, grupo): Observable<any> {
    var subject = new Subject<any>();
    let dados: any = [];

    this.list(`G038,G031/*/*/G038.IDG032=${component},G038.IDG031=${grupo},G038.IDG031=G031.IDG031`).subscribe(
      data => {
        let tipo;
        for (let item of data.data) {
          dados.push(item.ACAO);
          tipo = item.TIPO;
        }


        subject.next({ value: dados, tipo: tipo });
      },
      error => {
        this.utils.msgError('Ops, houve um erro em sua requisição!');
      }
    );

    return subject.asObservable();
  }

  gravaLog(tabela, id_dado, usuario, operacao, old?, novo?) {
    let obj = {
      TABELA: tabela,
      ID: id_dado,
      USER: usuario,
      OPERACAO: operacao,
      OLD: old,
      NEW: novo,
    };

    this.add('L001', obj).subscribe(
      data => {},
      error => {}
    );
  }

  getRegiao() {
    let observ = new Subject();

    if (this.global.getUser()) {
      this.list(
        `G004,E005,G003,G001/*/*/G004.IDG004=${
          this.global.getUser().IDG004
        },G004.IDE005=E005.IDE005,G003.IDG003=G004.IDG003,G001.IDG001=G003.IDG001/G004.*, E005.NOME AS TIPOLOCAL, G001.IDG001`
      ).subscribe(data => {
        let local = data.data[0];
        this.list('G001/*/*/IDG001=' + local.IDG001).subscribe(data => {
          observ.next(data.data[0]);
        });
      });
    }

    return observ.asObservable();
  }

  gerarBoleto(obj) {

    return this.http.post<any>(this.global.url + '/boletos/gerarBoleto', obj, {
      headers: this.getHeaders(),
    });
  }

  autorizacao(dados) {
    return this.http.post<any>(this.global.url + '/auth/autorizacao', dados, {
      headers: this.getHeaders(),
    });
  }

  deleteCamposFiltro(tabela, camposForm, camposBD) {
    let dados = {
      tabela: tabela,
      camposForm: camposForm,
      camposBD: camposBD,
    };

    return this.http.post<any>(this.global.url + '/functions/deletarCampos', dados, { headers: this.getHeaders() });
  }

  verificaExclusao(dados) {
    return this.http.post<any>(this.global.url + '/functions/verificaExclusao', dados, { headers: this.getHeaders() });
  }

  getArquivoContabil(dados) {
    return this.http.post<any>(this.global.url + '/relatorios/arquivoContabil', dados, { headers: this.getHeaders() });
  }

  getLogArquivoContabil(dados) {
    return this.http.post<any>(this.global.url + '/relatorios/getLogArquivo', dados, { headers: this.getHeaders() });
  }
}
