import { LocaisSelectComponentService } from 'app/main/shared/components/locais-select-component/locais-select-component.service';
import { Component, OnInit, ViewChild, ElementRef, Inject, Input } from '@angular/core';

import {
    MatTableDataSource,
    MatSort,
    MatPaginator
} from '@angular/material';

import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DataService } from '../../services/data.service';
import { UtilsService } from '../../services/utils.service';

export interface MembroData {
    STS: string;
    IDG009: string;
    NOME: string;
    EMAIL: string;
    CPF: string;
    VALOR: string;
}

@Component({
    selector: 'app-membroslist',
    templateUrl: './membros.component.html',
    styleUrls: ['./membros.component.scss']
})

export class MembrosComponentDialog implements OnInit {

    @ViewChild('TABLE') tableexport: ElementRef;

    @Input() valores;
    @Input() valoresModal;
    @Input() edit;
    @Input() observ;

    form: FormGroup;

    membros = [];
    dados: any = [];
    tableView: boolean;
    total: number = 0;

    // TABELAS
    tb = 'G030';
    tbMembros = 'G009';
    tbLocais = 'G004';

    displayedColumnsMembros: string[] = ['FOTO', 'NOME', 'CPF', 'VALOR', 'DATA'];
    dataSourceMembros: MatTableDataSource<MembroData>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private formBuilder: FormBuilder,
        private dataService: DataService,
        private utilsService: UtilsService,
        private nav: LocaisSelectComponentService
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            VALOR: [0]
        });

        this.form.get('VALOR').disable();

        this.listMembrosIgreja(this.nav.getLocalSelected());      
    }

    listMembrosIgreja(id) {
        this.dataService.list(`G009/NOME/asc/IDG004=${id},DELETED=N,STS=A`)
            .subscribe(
                data => {
                    this.membros = data.data;

                    this.setControls();

                    this.dataSourceMembros = new MatTableDataSource(this.membros);
                    if (this.tableView) {
                        this.utilsService.paginatorTranslate(this.paginator);
                    }
                    this.dataSourceMembros.paginator = this.paginator;
                    this.dataSourceMembros.sort = this.sort;

                    this.dataSourceMembros.filterPredicate = function (data, filter: string): boolean {
                        return data.IDG009.toString().includes(filter) ||
                            data.NOME.toLowerCase().includes(filter.toLowerCase()) ||
                            data.CPF.toLowerCase().includes(filter.toLowerCase())
                    };

                    if (this.valores || this.valoresModal) {
                        if (this.valoresModal){
                            this.form.patchValue(this.valoresModal)
                        }
                        else {
                            this.valores.subscribe(
                                data => {
                                    this.form.patchValue(data);
                                }
                            )
                        }
                    }

                    if(!this.edit){
                        this.form.disable();
                    }
                    else {
                        this.form.enable();
                        this.form.get('VALOR').disable();
                    }
                }
            )
    }

    applyFilterMembros(filterValue: string) {
        this.dataSourceMembros.filter = filterValue.trim().toLowerCase();

        if (this.dataSourceMembros.paginator) {
            this.dataSourceMembros.paginator.firstPage();
        }
    }

    setControls() {
        for (let i = 0; i < this.membros.length; i++) {
            let membro = this.membros[i];

            this.form.setControl(
                `VALOR_${membro.IDG009}`,
                new FormControl(0)
            );

            this.form.setControl(
                `DATA_${membro.IDG009}`,
                new FormControl(null)
            );
        }
    }

    soma() {
        let resultado = 0;
        for (let i = 0; i < this.membros.length; i++) {
            let membro = this.membros[i];
            if (this.form.get('VALOR_' + membro.IDG009).value != null) {
                resultado = resultado + this.form.get('VALOR_' + membro.IDG009).value;
            }
        }

        this.form.get("VALOR").setValue(resultado);

        if (this.observ){
            this.observ.next(resultado);
        }
    }

    getFormValue(){
        return this.form.getRawValue();
    }
}