import { NgControl } from '@angular/forms';
import { Input, Directive } from '@angular/core';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (!this.ngControl) {
      return;
    }
    if (this.ngControl.control) {
      this.ngControl.control[action]();
    }
  }

  constructor(private ngControl: NgControl) {}
}
