import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanActivate } from '@angular/router';
import { DataService } from '../services/data.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private global: GlobalService,
        private dataService: DataService
    ) { }

    permissoes;
    urlAtual;

    //TERMINAR -> Guarda incompleta 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        return new Promise((resolve, reject) => {
            
            if (!this.global.getUser() || !this.global.getToken()) {
                this.router.navigate(['/conta/entrar']);
                resolve(false);
            }
            else {
                this.dataService.isAuthenticated().toPromise().then(
                    result => {
                        if (result) {
                            resolve(true);
                        }
                        else {
                            this.router.navigate(['/conta/entrar']);
                            resolve(false);
                        }
                    }
                ).catch(
                    error => {
                        this.router.navigate(['/conta/entrar']);
                        resolve(false);
                    }
                )
            }
        })
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        return new Promise((resolve, reject) => {
            let dados = {
                grupo: this.global.getUser().IDG031,
                url: state.url
            }

            this.dataService.autorizacao(dados).toPromise().then(
                result => {
                    
                    if(!result.autorizado){
                        this.router.navigate(['/inicio']);
                    }

                    resolve(true);
                }
            ).catch(
                error => {
                    this.router.navigate(['/inicio']);
                    resolve(false);
                }
            )
            
        })
    }
}