import { Directive, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableLancamento]',
})
export class DisableLancamentoDirective {
  statusFECHADO = ['APROVADO', 'FECHADO', 'EM PARCELAMENTO', 'AGUARDANDO FECHAMENTO', 'AGUARDANDO PAGAMENTO'];

  @Input() set disableLancamento(value: any) {
    if (!this.ngControl) {
      return;
    }
    if (!this.statusFECHADO.includes(value)) {
      this.ngControl.control['enable']();
    } else {
      this.ngControl.control['disable']();
    }
  }

  constructor(private elRef: ElementRef, private ngControl: NgControl) {}
}
