import { UtilsService } from '../../services/utils.service';
import { DataService } from '../../services/data.service';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { FiltersService } from '../../services/filters.service';

export interface ReceitaData {
  IDE024: string;
  NOME: string;
  CARGO: string;
  REGIME: string;
  DTFIM: string;
  DTINICIO: string;
  STS: string;
}

@Component({
  selector: 'app-nomeacao-component',
  templateUrl: './nomeacao-component.component.html',
  styleUrls: ['./nomeacao-component.component.scss']
})

export class NomeacaoComponentComponent implements OnInit {
  @ViewChild('TABLE') tableexport: ElementRef;
  @ViewChild('formDirective') formDirective: FormGroupDirective;

  form: FormGroup;

  @Input('id') id;
  @Input('coluna') coluna;
  @Input('edit') edit;
  @Input('tipo') tipo;

  locais;

  rows = [];

  receita;

  membros = [];
  cargos = [];
  regimes = [];

  novasNomeacoes;
  novasRemocoes;
  atualizarNomeacoes;

  nomeacao;

  public membrosFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public IDG009FILTER: FormControl = new FormControl();

  public cargosFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public IDE001FILTER: FormControl = new FormControl();

  public regimesFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public IDE003FILTER: FormControl = new FormControl();

  private _onDestroy = new Subject<void>();

  tb = 'E024';

  displayedColumns: string[] = ['FOTO', 'NOME', 'CARGO', 'REGIME', 'DTINICIO', 'DTFIM', 'VISUALIZAR'];
  dataSource: MatTableDataSource<ReceitaData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private utilsService: UtilsService,
    private filters: FiltersService
    ) {
    this.novasNomeacoes = [];
    this.novasRemocoes = [];
    this.atualizarNomeacoes = [];
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      IDG009: [{ value: null, disabled: !this.edit }, Validators.required],
      IDE001: [{ value: null, disabled: !this.edit }, Validators.required],
      IDE003: [{ value: null, disabled: !this.edit }, Validators.required],
      DTINICIO: [{ value: null, disabled: !this.edit }, [Validators.required]],
      DTFIM: [{ value: null, disabled: !this.edit }],
    });

    this.list();
    this.getCargos();
    this.getRegimes();

    this.IDG009FILTER.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMembros();
      });

    this.IDE001FILTER.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCargos();
      });

    this.IDE003FILTER.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRegimes();
      });
  }

  alertMaxLenght(value: string, max: number) {
    if (value && value.length == max) {
      this.utilsService.alertMaxLenght(max);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private filterMembros() {
    this.filters.genericFilter(this.membros, this.IDG009FILTER, this.membrosFilter, 'NOME');
  }

  private filterCargos() {
    this.filters.genericFilter(this.cargos, this.IDE001FILTER, this.cargosFilter, 'NOME');
  }

  private filterRegimes() {
    this.filters.genericFilter(this.regimes, this.IDE003FILTER, this.regimesFilter, 'NOME');
  }

  getMembros(id) {
    let rota;
    switch(this.tipo){
      case 'REGIONAL':
        rota = `G009,G004,G003/G009.NOME/asc/G003.IDG001=${id},G003.IDG003=G004.IDG003,G009.IDG004=G004.IDG004,G009.DELETED=N,G009.STS=A,G004.DELETED=N,G004.STS=A,G003.DELETED=N,G003.STS=A/G009.*`;
        break;
      case 'DISTRITAL':
        rota = `G009,G004/G009.NOME/asc/G004.IDG003=${id},G009.IDG004=G004.IDG004,G009.DELETED=N,G009.STS=A,G004.DELETED=N,G004.STS=A/G009.*`;
        break;
      case 'LOCAL':
        rota = `G009/NOME/asc/IDG004=${id},DELETED=N,STS=A`;
        break;
    }

    if (rota) {
      this.dataService.list(rota)
        .subscribe(
          data => {
            this.membros = data.data;

            this.membrosFilter.next(this.membros.slice());
          },
          error => {
            this.utilsService.msgError('Ops, houve um erro na requisição!');
          })
    }
  }

  getCargos() {
    this.dataService.list(`E001/NOME/desc/DELETED=N,STS=A`)
      .subscribe(
        data => {
          this.cargos = data.data;

          this.cargosFilter.next(this.cargos.slice());
        },
        error => {
          this.utilsService.msgError('Ops, houve um erro na requisição!');
        })
  }

  getRegimes() {
    this.dataService.list(`E003/NOME/desc/DELETED=N,STS=A`)
      .subscribe(
        data => {
          this.regimes = data.data;

          this.regimesFilter.next(this.regimes.slice());
        },
        error => {
          this.utilsService.msgError('Ops, houve um erro na requisição!');
        })
  }

  list() {
    this.novasNomeacoes = [];
    this.novasRemocoes = [];
    this.atualizarNomeacoes = [];
    this.dataSource = null;
    let rota;

    if (!this.id) {
      this.rows = [];
      this.dataSource = new MatTableDataSource(this.rows);
      if (this.paginator) {
        this.utilsService.paginatorTranslate(this.paginator);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    else {
      rota = `E024,G009,E001,E003/E024.IDE024/desc/${this.coluna}=${this.id},E024.DELETED=N,E024.STS=A,E024.IDG009=G009.IDG009,E024.IDE001=E001.IDE001,E024.IDE003=E003.IDE003/E024.*,G009.NOME, G009.FOTO,E001.NOME AS CARGO,E003.NOME AS REGIME`;
      this.dataService.list(rota)
        .subscribe(
          data => {
            this.rows = data.data;
            
            this.rows = this.utilsService.formatTimeZone('DTINICIO', this.rows);
            this.rows = this.utilsService.formatTimeZone('DTFIM', this.rows);

            this.dataSource = new MatTableDataSource(this.rows);
            if (this.paginator) {
              this.utilsService.paginatorTranslate(this.paginator);
            }
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          },
          error => {
            this.utilsService.msgError('Ops, houve um erro na requisição!');
          })
    }
  }

  salvar(nomeacao) {
    if (this.form.valid) {
      if (this.verificarDuplicidade()){
        if (nomeacao) {
          this.update(nomeacao);
        }
        else{
          this.add();
        }
      }
      else {
        this.utilsService.msgError('Nomeação já adicionada!');
      }
    }
    else {
      this.utilsService.msgError('Verifique se todos campos foram preenchidos!');
    }
  }

  verificaDTs() {
    this.form = this.utilsService.compara2Datas(this.form, 'DTFIM', 'DTINICIO');
  }

  add() {
    let indexMembro = this.membros.findIndex(val => val.IDG009 == this.form.value.IDG009);
    let indexCargo = this.cargos.findIndex(val => val.IDE001 == this.form.value.IDE001);
    let indexRegime = this.regimes.findIndex(val => val.IDE001 == this.form.value.IDE004);
    

    let membro = this.membros[indexMembro];
    let cargo = this.cargos[indexCargo];
    let regime = this.regimes[indexRegime];

    let nomeacao = {
      IDE024: null,
      NOME: membro.NOME,
      FOTO: membro.FOTO,
      CARGO: cargo.NOME,
      REGIME: regime.NOME,
      DTFIM: this.form.value.DTFIM ? this.utilsService.formatTimeZoneUnique(this.form.value.DTFIM) : null,
      DTINICIO: this.utilsService.formatTimeZoneUnique(this.form.value.DTINICIO),
      STS: 'A'
    }

    this.dataSource = null;
    this.rows.push(nomeacao);
    this.novasNomeacoes.push(this.form.value);
    this.dataSource = new MatTableDataSource(this.rows);
    
    this.resetForm();
  }

  update(nomeacao) {
    let indexMembro = this.membros.findIndex(val => val.IDG009 == this.form.value.IDG009);
    let indexCargo = this.cargos.findIndex(val => val.IDE001 == this.form.value.IDE001);
    let indexRegime = this.regimes.findIndex(val => val.IDE001 == this.form.value.IDE004);

    let membro = this.membros[indexMembro];
    let cargo = this.cargos[indexCargo];
    let regime = this.regimes[indexRegime];

    if (nomeacao.IDE024) {
      let atualizado = {
        IDE024: nomeacao.IDE024,
        IDG009: this.form.value.IDG009,
        IDE001: this.form.value.IDE001,
        IDE003: this.form.value.IDE003,
        DTFIM: this.form.value.DTFIM ? this.utilsService.formatTimeZoneUnique(this.form.value.DTFIM) : null,
        DTINICIO: this.utilsService.formatTimeZoneUnique(this.form.value.DTINICIO),
      }
      this.atualizarNomeacoes.push(atualizado);
    }

    let atualizado = {
      IDE024: nomeacao.IDE024,
      NOME: membro.NOME,
      FOTO: membro.FOTO,
      CARGO: cargo.NOME,
      REGIME: regime.NOME,
      DTFIM: this.form.value.DTFIM ? this.utilsService.formatTimeZoneUnique(this.form.value.DTFIM) : null,
      DTINICIO: this.utilsService.formatTimeZoneUnique(this.form.value.DTINICIO),
      STS: nomeacao.STS
    }

    this.dataSource = null;
    let indexRow = this.rows.findIndex(val => val.IDE024 == nomeacao.IDE024);
    this.rows[indexRow] = atualizado;
    this.dataSource = new MatTableDataSource(this.rows);

    this.nomeacao = null;

    this.resetForm();
  }

  resetForm() {
    this.form.reset();
    this.form.clearValidators();
    this.formDirective.resetForm();
  }


  view(nomeacao) {
    this.nomeacao = nomeacao;
  }

  del(item, index) {
    this.utilsService.confirmRemove()
      .then((willDelete) => {
        if (willDelete) {
          if (item.IDE024) {
            this.novasRemocoes.push(item.IDE024);
          }
          else {
            let cont = -1;
            for (let i = 0; i <= index; i++){
              if (!this.rows[i].IDE024){
                cont++;
              }
            }
            
            delete this.novasNomeacoes[cont];

            this.novasNomeacoes = this.novasNomeacoes.filter(function (el) {
              return el != null;
            });
          }
          
          this.dataSource = null;
          delete this.rows[index];

          this.rows = this.rows.filter(function (el) {
            return el != null;
          });
          this.dataSource = new MatTableDataSource(this.rows);
        }
      });
  }

  returnLengthArray() {
    return this.rows.length;
  }

  delItens(observ) {
    let cont = this.novasRemocoes.length;

    if (cont == 0) {
      observ.next(0);
    }
    else {
      for (let i = 0; i < this.novasRemocoes.length; i++) {
        if (this.novasRemocoes[i] != null) {
          this.dataService.del(this.tb, this.novasRemocoes[i], 'IDE024', 'N')
            .subscribe(
              data => {
                cont--;
                observ.next(cont);
              },
              error => {
                this.utilsService.msgError('Erro ao excluir nomeação!');
              })
        }
      }
    }
  }

  addItens(observ, id) {
    let cont = this.novasNomeacoes.length;
    
    if (cont == 0) {
      observ.next(0);
    }
    else {
      
      for (let i = 0; i < this.novasNomeacoes.length; i++) {
        this.novasNomeacoes[i].DTINICIO = this.utilsService.formatDate(this.novasNomeacoes[i].DTINICIO);
        this.novasNomeacoes[i].DTFIM = this.novasNomeacoes[i].DTFIM ? this.utilsService.formatDate(this.novasNomeacoes[i].DTFIM) : null;
        this.novasNomeacoes[i][this.coluna] = id;
        this.dataService.push(this.tb, this.novasNomeacoes[i]).subscribe(data => {
          if (data.value == true) {
            cont--;
            observ.next(cont);
          }
        })
      }
    }
  }

  updateItens(observ) {
    let cont = this.atualizarNomeacoes.length;
    if (cont == 0) {
      observ.next(0);
    }
    else {
      for (let i = 0; i < this.atualizarNomeacoes.length; i++) {
        this.atualizarNomeacoes[i].DTINICIO = this.utilsService.formatDate(this.atualizarNomeacoes[i].DTINICIO);
        this.atualizarNomeacoes[i].DTFIM = this.atualizarNomeacoes[i].DTFIM ? this.utilsService.formatDate(this.atualizarNomeacoes[i].DTFIM) : null;
        this.atualizarNomeacoes[i][this.coluna] = this.id;

        this.dataService.update('E024', this.atualizarNomeacoes[i].IDE024, "IDE024", this.atualizarNomeacoes[i]).subscribe(
          data => {
            cont--;
            observ.next(cont);
          }
        )
      }
    }
  }
  
  setDisabled() {
    this.form.disable();
  }

  enable() {
    this.form.enable();
  }

  formatDate(data) {
    if (data != null) {
      return moment(data).format('DD/MM/YYYY');
    }
    return '';
  }

  verificarDuplicidade(){
    let value = this.form.value;

    let index = -1;

    index = this.rows.findIndex(val => parseInt(value.IDE001) == val.IDE001 && parseInt(value.IDG009) == val.IDG009);
    if (index == -1){
      index = this.novasNomeacoes.findIndex(val => parseInt(value.IDE001) == val.IDE001 && parseInt(value.IDG009) == val.IDG009);
    }

    return index == -1;
  }
}