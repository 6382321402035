import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'app/main/shared/services/global.service';
import { Observable } from 'rxjs/internal/Observable';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class ContasBancariasStoreService {
  urlAPI = `${this.global.url}/v2/contas-bancarias/`;

  constructor(private logger: NGXLogger, public global: GlobalService, private http: HttpClient) {}

  // router.get('/contas-bancarias/com-saldo/:idLocal/idLanc/:status', async (req, res, next) => {

  loadContasBancariasComSaldoByLocalAndLancamentoAndStatus(payload: any): Observable<any> {
    // Busca contas bancárias que tenham saldo deste LOCAL, lançamento com status X
    const result = this.http.get<any>(`${this.urlAPI}com-saldo/${payload.idLocal}/${payload.idLanc}/${payload.status}`);
    return result;
  }


  loadContasBancariasComSaldoByLancamento(payload: any): Observable<any> {
    // Busca contas bancárias que tenham saldo deste LOCAL, lançamento com status X
    // contas-bancarias/com-saldo-2/:idLocal/:idLanc/:ano/:mes
    const result = this.http.get<any>(`${this.urlAPI}com-saldo-2/${payload.idLocal}/${payload.idLanc}/${payload.ano}/${payload.mes}`);
    return result;
  }

  loadContasBancariasComSaldoByLancamentoAjusteDeSaldo(payload: any): Observable<any> {
    // Busca contas bancárias que tenham saldo deste LOCAL, lançamento com status X
    // contas-bancarias/com-saldo-2/:idLocal/:idLanc/:ano/:mes
    const result = this.http.get<any>(`${this.urlAPI}ajuste-saldo/${payload.idLocal}/${payload.idLanc}`);
    return result;
  }

  loadContasBancariasComSaldoByLocal(payload: any): Observable<any> {
    // Busca contas bancárias que tenham saldo deste LOCAL
    const result = this.http.get<any>(`${this.urlAPI}com-saldo/${payload.idLocal}`);
    return result;
  }
}
