import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'app/main/shared/services/global.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class MovimentacoesStoreService {
  urlAPI = `${this.global.url}/v2/movimentacoes/`;

  constructor(public global: GlobalService, private http: HttpClient) {}

  // http://localhost:3000/api/v2/movimentacoes/lancamento?IDG034=1881
  // Receitas agrupadas por Grupo de Receita

  loadMovimentacoesByLancamento(payload): Observable<any> {
    const result = this.http.get<any>(`${this.urlAPI}lancamento`, { params: { IDG034: payload } });
    return result;
  }

  loadMovimentacoesByLancamentoReceitaOrDespesa(payload): Observable<any> {
    const result = this.http.get<any>(`${this.urlAPI}${payload.IDG034}/${payload.tipoMov}/${payload.idMov}`);
    return result;
  }
}
