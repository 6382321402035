import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewLocaisRoutingModule } from './new-locais-routing.module';

@NgModule({
  imports: [
    CommonModule,
    NewLocaisRoutingModule
  ],
  declarations: []
})
export class NewLocaisModule { }
