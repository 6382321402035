import { LocaisSelectComponentComponent } from './components/locais-select-component/locais-select-component.component';
import { ModalComponent } from './components/modal/modal.component';
import { HttpClientModule } from '@angular/common/http';

import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSaverModule } from 'ngx-filesaver';

import {
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatStepperModule,
  MatTooltipModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatMenuModule,
  MatCheckboxModule,
  MatDialogModule,
  MatProgressSpinnerModule

} from '@angular/material';

import { CurrencyMaskModule } from 'ng2-currency-mask';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseSharedModule } from '@fuse/shared.module';

import { TextMaskModule } from 'angular2-text-mask';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CdkTableModule } from '@angular/cdk/table';

import { CpfCnpjModule } from 'ng2-cpf-cnpj';
import { FiltrosComponent } from './filtros/filtros.component';
import { NomeacaoComponentComponent } from './components/nomeacao-component/nomeacao-component.component';
import { UploadComponentComponent } from './components/upload-component/upload-component.component';

import { DropzoneModule, DropzoneConfigInterface,
  DROPZONE_CONFIG } from 'ngx-dropzone-wrapper'
import { SafePipe } from './pipes/safe-pipe';
import { ModalItem } from './components/upload-component/modalItem/modalItem.component';
import { FuncionariosComponentDialog } from './components/funcionarios/funcionarios.component';
import { LocaisComponentDialog } from './components/locais/locais.component';
import { ParceirosComponentDialog } from './components/parceiros/parceiros.component';
import { MembrosComponentDialog } from './components/membros/membros.component';
import { getDutchPaginatorIntl } from './paginator-intl';
import { NgxDropzoneModule } from 'ngx-dropzone';


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  acceptedFiles: '.pdf, .png, .jpeg, .jpg, .doc, .docx',
  createImageThumbnails: true
};


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxViacepModule,
    MatSnackBarModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatProgressSpinnerModule,

    FuseSharedModule,
    TextMaskModule,

    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    NgxViacepModule,
    MatTooltipModule,
    CdkTableModule,
    NgxMatSelectSearchModule,
    CpfCnpjModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDialogModule,
    CurrencyMaskModule,
    DropzoneModule,
    FileSaverModule,
    NgxDropzoneModule
  ],
  declarations: [
    ModalComponent,
    NomeacaoComponentComponent, 
    FiltrosComponent,
    LocaisSelectComponentComponent, 
    UploadComponentComponent, 
    SafePipe, 
    ModalItem,
    FuncionariosComponentDialog,
    LocaisComponentDialog,
    MembrosComponentDialog,
    ParceirosComponentDialog,
  ],
  exports: [
    ModalComponent,
    NomeacaoComponentComponent,
    FiltrosComponent,
    LocaisSelectComponentComponent,
    UploadComponentComponent,
    FuncionariosComponentDialog,
    LocaisComponentDialog,
    MembrosComponentDialog,
    ParceirosComponentDialog,
    MatProgressSpinnerModule,
    NgxDropzoneModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
  ],
  entryComponents: [ 
    ModalItem
  ]
})
export class SharedModule { }
