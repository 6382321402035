import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';
import { MatCheckboxModule  } from '@angular/material';
import { FuseContentComponent } from './content.component';

@NgModule({
    declarations: [
        FuseContentComponent
    ],
    imports     : [
        RouterModule,
        MatCheckboxModule,
        FuseSharedModule,
    ],
    exports: [
        FuseContentComponent
    ],
})
export class FuseContentModule
{
}
