import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { MovimentacoesService } from 'app/main/content/administrativo/movimentacoes-entre-contas/movimentacoes-entre-contas.service';
import { ContasService } from 'app/main/content/financeiro/contas/contas.service';
import { UtilsService } from 'app/main/shared/services/utils.service';
import { ContasBancariasStoreService } from 'app/modulos/new-contas-bancarias/contas-bancarias-store/contas-bancarias-store.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { MovimentacoesStoreService } from '../movimentacoes-store/movimentacoes-store.service';
import { NGXLogger } from 'ngx-logger';

interface Person {
  name: string;
  lastName: string;
  age: number;
}

@Component({
  selector: 'app-form-dlg-movimentacoes',
  templateUrl: './form-dlg-movimentacoes.component.html',
  styleUrls: ['./form-dlg-movimentacoes.component.scss'],
})
export class FormDlgMovimentacoesComponent implements OnInit {
  isDev = !environment.production;

  private subscriptions: Subscription[] = [];
  referencia: any;
  lancamento: any;
  movimentacao: any;
  id: any;
  edit: any;
  idLocal: any;
  tipoMov: any;
  nomeLocal: any;
  formControlNameString: any;
  mes: any;
  ano: any;

  // movimentacoes: any;

  formCRUD = this.fb.group({
    VALOR: ['', Validators.required],
    DATA: ['', Validators.required],
    CONTA: ['', Validators.required],
    HISTORICO: [''],
  });

  dtmes;
  dtdia;
  dtano;
  dataChange;

  contasBancariasLocal: any;
  // public contasFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // public CONTAFILTER: FormControl = new FormControl();
  isValid: boolean = true;
  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<FormDlgMovimentacoesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private utils: UtilsService,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private movimentacoesStoreService: MovimentacoesStoreService,
    private movimentacoesService: MovimentacoesService,
    private contasService: ContasService,
    private contasBancariasStoreService: ContasBancariasStoreService
  ) {
    this.tipoMov = this.data.tipoMov;
    this.referencia = this.data.referencia;
    this.lancamento = this.data.lancamento;
    this.movimentacao = this.data.movimentacao;
    this.mes = this.data.mes;
    this.ano = this.data.ano;
    this.id = this.data.id;
    this.edit = this.data.edit;
    this.idLocal = this.data.idLocal;
    this.nomeLocal = this.data.nomeLocal;
    this.formControlNameString = this.data.formControlNameString;

    this.getContasBancarias();
  }

  ngOnInit() {
    
    
    if (this.data.ano != undefined) {
      this.dataChange = `${this.data.ano}-${this.data.mes.length > 1 ? this.data.mes : '0' + this.data.mes}-01`;
    }
  }

  ngAfterViewInit() {
    if (this.movimentacao) {
      
      // const dtMovimentacao = moment(new Date(this.movimentacao.DATA), `DD.MM.YYYY`);
      // this.data.data = this.utils.formatTimeZone('DATA', data);

      const dtMovimentacao = moment(new Date(this.movimentacao.DATA), `DD-MM-YYYY`);

      

      this.formCRUD.patchValue({
        VALOR: this.movimentacao.VALOR,
        DATA: dtMovimentacao.format(`YYYY-MM-DD`),
        CONTA: this.movimentacao.IDG006,
        HISTORICO: this.movimentacao.HISTORICO,
      });

      // this.formCRUD.patchValue(this.movimentacao);
    } else {
    }
    this.ngDoCheck();
  }

  submit(form: FormGroup) {
    
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  /*
OBJ ADD 

form:
CONTA: "65"
DATA: "2020-08-07"
HISTORICO: "teste"
VALOR: 0.27
 valoresModal: undefined
*/

  onConfirm() {
    this.realizarMovimentacao(
      { form: this.formCRUD.getRawValue(), valoresModal: null },
      this.data.referencia,
      this.data.formControlNameString,
      this.data.id
    );
  }

  realizarMovimentacao(result, referencia, fcname, id) {
    let snack = this.utils.getSnack('yellow', 'Gravando Movimentação ...');

    let movimentacao = {
      IDG006: result.form.CONTA,
      TIPO: referencia.IDG019 ? 'E' : 'S',
      VALOR: result.form.VALOR,
      IDG034: id,
      DATA: result.form.DATA,
      ORIGEM: 'AUT',
      IDG019: referencia.IDG019 ? referencia.IDG019 : null,
      IDG021: referencia.IDG021 ? referencia.IDG021 : null,
      FUNCAO: 'LANÇAMENTOS',
      FCNAME: fcname,
      HISTORICO: result.form.HISTORICO ? result.form.HISTORICO.toUpperCase() : null,
    };

    let valoresIndividuais = {
      arrValores: result.valoresModal,
      referencia: referencia.REFERENCIA,
    };

    

    this.movimentacoesService
      .realizarMovimentacao({ movimentacao: movimentacao, valoresIndividuais: valoresIndividuais })
      .subscribe(
        data => {
          
          snack.dismiss();
          this.dialogRef.close(this.formCRUD.getRawValue());
          this.utils.msgSuccess('Registro salvo!');
        },
        error => {
          this.utils.msgError('Erro ao gravar movimentação!');
        }
      );

    snack.dismiss();
  }

  ngDoCheck() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onChangeTipoContaBancaria(event) {
    
  }

  getContasBancarias() {
    this.subscriptions.push(
      this.contasBancariasStoreService
        .loadContasBancariasComSaldoByLocal({
          idLocal: this.idLocal,
        })
        .pipe(untilDestroyed(this))
        .subscribe((data: any) => {
          this.contasBancariasLocal = data.value;
        })
    );
  }
}
//         this.peculio.patchValue(peculioLancamento);
