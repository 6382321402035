import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'app/main/shared/services/global.service';
import { DataService } from 'app/main/shared/services/data.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class LocaisService {
  private subscribersgetLocaisCount = 0;

  constructor(
    private logger: NGXLogger,
    public http: HttpClient,
    public global: GlobalService,
    private dataService: DataService
  ) {
    // this.getLocaisByPermissao();
  }

  getHeaders() {
    let headers = new HttpHeaders({
      authorization: this.global.getToken(),
    });
    return headers;
  }

  getSede(id) {
    let obj = {
      IDG004: id,
    };
    return this.http.post<any>(this.global.url + '/locais/getSede', obj, {
      headers: this.getHeaders(),
    });
  }

  public getSubscribersgetLocaisCount(): number {
    return this.subscribersgetLocaisCount;
  }

  getLocais() {
    this.subscribersgetLocaisCount++;

    return this.http.post<any>(this.global.url + '/locais/getLocais', null, {
      headers: this.getHeaders(),
    });
  }

  getLocal(id) {
    let obj = {
      IDG004: id,
    };
    return this.http.post<any>(this.global.url + '/locais/getLocalByIdWithInformation', obj, {
      headers: this.getHeaders(),
    });
  }

  getLocalAllData(id) {
    let obj = {
      IDG004: id,
    };
    console.log('Buscando local', id);
    return this.http.post<any>(this.global.url + '/locais/getLocalByIdWithAllInformation', obj, {
      headers: this.getHeaders(),
    });
  }

  getLocaisByPermissao() {
    let obj = {
      IDG009: this.global.getUser().IDG009,
    };

    return this.http.post<any>(this.global.url + '/locais/getLocaisByPermissao', obj, { headers: this.getHeaders() });
  }

  getTipo() {
    return this.dataService.list(`G031/*/*/IDG031=${this.global.getGrupo()}/TIPO`);
  }

  //  LOCAIIS DESCONTOS
  getDescontosByLocal(id) {
    
    return this.http.get<any>(this.global.url + `/locais/descontos/${id}`, {
      headers: this.getHeaders(),
    });
  }

  getByIdCreated(idLocal, dtCreatedLancamento) {
    // /api/locais/descontos/:idLocal/:dtCreated
    
    return this.http.get<any>(this.global.url + `/locais/descontos/${idLocal}/${dtCreatedLancamento}`, {
      headers: this.getHeaders(),
    });
  }

  insert(payload) {
    
    const sendData = this.http.post<any>(this.global.url + `/locais/descontos`, payload, {
      headers: this.getHeaders(),
    });

    return sendData;
  }
}
