import { Input } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';

import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DataService } from '../../services/data.service';
import { UtilsService } from '../../services/utils.service';
import { NGXLogger } from 'ngx-logger';

export interface LocaisData {
  IDG004: string;
  NOME: string;
  VALOR: string;
}

@Component({
  selector: 'app-locaislist',
  templateUrl: './locais.component.html',
  styleUrls: ['./locais.component.scss'],
})
export class LocaisComponentDialog implements OnInit {
  @ViewChild('TABLE') tableexport: ElementRef;

  form: FormGroup;

  @Input() valores;
  @Input() valoresModal;
  @Input() edit;
  @Input() observ;

  locais = [];
  dados: any = [];
  tableView: boolean;
  total: number = 0;

  displayedColumnsLocais: string[] = ['NOME', 'VALOR', 'DATA'];
  dataSourceLocais: MatTableDataSource<LocaisData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private logger: NGXLogger,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      VALOR: [0],
    });

    this.form.get('VALOR').disable();

    this.listLocais();
  }

  listLocais() {
    this.dataService.list(`G004/NOME/asc/DELETED=N,STS=A`).subscribe(data => {
      this.locais = data.data;

      this.setControls();

      this.dataSourceLocais = new MatTableDataSource(this.locais);
      if (this.tableView) {
        this.utilsService.paginatorTranslate(this.paginator);
      }
      this.dataSourceLocais.paginator = this.paginator;
      this.dataSourceLocais.sort = this.sort;

      this.dataSourceLocais.filterPredicate = function (data, filter: string): boolean {
        return data.IDG004.toString().includes(filter) || data.NOME.toLowerCase().includes(filter.toLowerCase());
      };

      if (this.valores || this.valoresModal) {
        if (this.valoresModal) {
          this.form.patchValue(this.valoresModal);
        } else {
          this.valores.subscribe(data => {
            this.form.patchValue(data);
          });
        }
      }

      if (!this.edit) {
        this.form.disable();
      } else {
        this.form.enable();
        this.form.get('VALOR').disable();
      }
    });
  }

  applyFilterLocais(filterValue: string) {
    this.dataSourceLocais.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceLocais.paginator) {
      this.dataSourceLocais.paginator.firstPage();
    }
  }

  setControls() {
    for (let i = 0; i < this.locais.length; i++) {
      let local = this.locais[i];

      this.form.setControl(`VALOR_${local.IDG004}`, new FormControl(0));

      this.form.setControl(`DATA_${local.IDG004}`, new FormControl(null));
    }
  }

  soma() {
    let resultado = 0;
    for (let i = 0; i < this.locais.length; i++) {
      let local = this.locais[i];
      if (this.form.get('VALOR_' + local.IDG004).value != null) {
        resultado = resultado + this.form.get('VALOR_' + local.IDG004).value;
      }
    }

    this.form.get('VALOR').setValue(resultado);

    if (this.observ) {
      this.observ.next(resultado);
    }
  }

  getFormValue() {
    return this.form.getRawValue();
  }
}
