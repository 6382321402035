import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'app/main/shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class ContasService {

  constructor(
    public http: HttpClient,
    public global: GlobalService  ) {

  }

  getHeaders() {
    let headers = new HttpHeaders({
      'authorization': this.global.getToken()
    });
    return headers;
  }

  getContasWithSaldosByIdLocal(id) {
    let obj = {
      IDG004: id
    }
    console.log(obj);
    return this.http.post<any>(this.global.url + '/contas/getContasWithSaldosByIdLocal', obj, { headers: this.getHeaders() });
  };

  getAllContasWithSaldos() {
    return this.http.post<any>(this.global.url + '/contas/getAllContasWithSaldos', {}, { headers: this.getHeaders() });
  };

}
