export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APLICACOES': 'Aplicações',
            'INICIO'        : {
                'TITLE': 'Início',
                'BADGE': '25'
            }
        }
    }
};
