import 'hammerjs';

import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CookieModule } from 'ngx-cookie';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { fuseConfig } from './fuse-config';
import { FuseMainModule } from './main/main.module';
import { AuthGuard } from './main/shared/guards/auth.guard';
import { RelatoriosService } from './main/shared/services/relatorios.service';
import { SharedModule } from './main/shared/shared.module';
import { NewConfiguracoesModule } from './modulos/new-configuracoes/new-configuracoes.module';
import { NewLocaisModule } from './modulos/new-locais/new-locais.module';
import { NewMovimentacoesModule } from './modulos/new-movimentacoes/new-movimentacoes.module';
import { NewPeculiosModule } from './modulos/new-peculios/new-peculios.module';
import { NewReceitasModule } from './modulos/new-receitas/new-receitas.module';
import { LoggingInterceptor } from './shared/interceptors/logging.interceptor';
import { AppStoreModule } from './store/store.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

registerLocaleData(localePt);

const appRoutes: Routes = [
  {
    path: 'cadastros-gerais',
    loadChildren: './main/content/cadastros-gerais/cadastros-gerais.module#CadastrosGeraisModule',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'pastoral',
    loadChildren: './main/content/pastoral/pastoral.module#PastoralModule',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'financeiro',
    loadChildren: './main/content/financeiro/financeiro.module#FinanceiroModule',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'administrativo',
    loadChildren: './main/content/administrativo/administrativo.module#AdministrativoModule',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'inicio',
    loadChildren: './main/content/inicio/inicio.module#InicioModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'alterar-dados',
    loadChildren: './main/content/membro-form/membro-form.module#MembroFormModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'sistema',
    loadChildren: './main/content/sistema/sistema.module#SistemaModule',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'conta',
    loadChildren: './main/content/inicio-pages/inicio-pages.module#InicioPagesModule',
  },
  {
    path: 'filtros',
    loadChildren: './main/content/filtros/filtros.module#FiltrosModule',
  },

  {
    path: 'configuracoes',
    loadChildren: './main/content/sistema/configuracoes/configuracoes.module#ConfiguracoesModule',
  },

  {
    path: 'administrativo/lancamentos',
    loadChildren: './modulos/new-lancamentos/new-lancamentos.module#NewLancamentosModule',
  },

  {
    path: 'administrativo/lancamentos-old',
    loadChildren: './main/content/administrativo/lancamentos/lancamentos.module#LancamentosModule',
  },  

  {
    path: '**',
    redirectTo: 'inicio',
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),

    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    FuseMainModule,
    SharedModule,
    AppStoreModule,
    CookieModule.forRoot(),
    NewReceitasModule,
    NewMovimentacoesModule,
    NewPeculiosModule,
    NewLocaisModule,
    NewConfiguracoesModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },

    RelatoriosService,
  ],
})
export class AppModule {}
