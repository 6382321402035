import { Injectable, OnDestroy } from '@angular/core';
import { LocaisService } from 'app/main/content/pastoral/estrutura/locais/locais.service';
import { DataService } from 'app/main/shared/services/data.service';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class LocaisSelectComponentService implements OnDestroy {
  subscriptions: Subscription[] = [];

  localSelected = null;

  locais;

  constructor(private logger: NGXLogger, private data: DataService, private locaisService: LocaisService) {
    // this.getLocais();
  }

  getLocais() {
    this.subscriptions.push(
      this.locaisService.getLocaisByPermissao().subscribe(data => {

        console.log(`data`, data)

        this.locais = data;

      })
    );
  }

  setLocalSelected(id) {
    this.localSelected = id;
  }

  getLocalSelected() {
    return this.localSelected;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed

    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
