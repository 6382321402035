import { GlobalService } from 'app/main/shared/services/global.service';
import { FormGroup } from '@angular/forms';
import { UtilsService } from './../../services/utils.service';
import { DataService } from 'app/main/shared/services/data.service';
import { MatSort, MatDialog } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from "moment";
import { DropzoneConfigInterface, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { ModalItem } from './modalItem/modalItem.component';
import { UploadComponentService } from './upload-component.service';

export interface UploadData {
  IDA001: string;
  DESC: string;
  DATA: string;
  NOME: string;
}

@Component({
  selector: 'app-upload-component',
  templateUrl: './upload-component.component.html',
  styleUrls: ['./upload-component.component.scss']
})

export class UploadComponentComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild(MatSort) sort: MatSort;

  @Input('tb') tb;
  @Input('edit') edit;
  @Input('id') idView;

  displayedColumns: string[] = ['DESC', 'EXTENSAO', 'DATA', 'NOME', 'ACOES'];

  dataSource: MatTableDataSource<UploadData>;

  form: FormGroup;

  arquivos = [];

  toDeleted = [];

  @ViewChild(DropzoneComponent) componentRef: DropzoneComponent;

  public config: DropzoneConfigInterface = {
    acceptedFiles: '.png, .jpeg, .jpg, .pdf, .doc, .docx, .xls, .cvs',
    createImageThumbnails: true,
    clickable: true,
    dictRemoveFile: 'Remover',
    addRemoveLinks: true,
    dictCancelUpload: "Cancelar",
    dictRemoveFileConfirmation: "Confirma a remoção?",
    url: this.global.url + '/data/storage',
    autoProcessQueue: false,
    maxFilesize: 10,
    dictFileTooBig: "Arquivo muito grande! (Max: 10MB)."
  };

  constructor(
    private data: DataService,
    private utils: UtilsService,
    private global: GlobalService,
    public dialogRef: MatDialog,
    private upload: UploadComponentService,
  ) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.toDeleted = [];
    this.arquivos = [];

    if (this.idView) {
      this.getArquivos(this.idView);
    }
  }

  getArquivos(id) {
    this.upload.getArquivos(id, this.tb).subscribe(
      data => {
        data.data = this.utils.formatTimeZone('DATA', data.data);

        this.dataSource = new MatTableDataSource(data.data);

        this.dataSource.sort = this.sort;
      }
    )
  }

  formatDate(data) {
    return moment(data).format("DD/MM/YYYY");
  }


  save(idNew?) {
    let drop = this.files;

    let id = idNew ? idNew : this.idView;

    let observ = new Subject();

    let cont = drop.length;

    for (let i = 0; i < drop.length; i++) {   
      let formData = new FormData();  
      this.popularImg(formData, drop[i]);      
      console.log('aquivo enviado', formData)
      this.data.storage(formData)
        .subscribe(
          data => {
            let dados = {
              URL: data[0],
              DATA: this.utils.formatDate(new Date()), 
              DESC: drop[i].name.toUpperCase().split('.')[0],
              EXTENSAO: drop[i].name.toUpperCase().split('.')[1],
              IDG003: null,
              IDG009: null,
              IDG013: null,
              IDG035: null,
              IDG014: null,
              IDG008: null,
              IDG016: null,
              IDG040: null,
              IDG007: null,
              IDG002: null,
              IDG041: null,
              IDG042: null,
              IDG001: null,
              IDG004: null,
              USER: this.global.getUser().IDG009
            }

            dados['ID' + this.tb] = id;
            console.log('tabela banco', dados)
            this.data.add('A001', dados).subscribe(
              data => {
                if (i + 1 == cont) {
                  observ.next();
                }
              }
            )
          },
          error => {
            this.utils.msgError("Erro ao realizar upload!");
          }
        )
    }
    if (cont == 0){
      setTimeout(
        function(){ 
        observ.next(); 
      }, 500);
    }
    
    return observ.asObservable();
  }

  delUploads(){
    this.upload.delUploads(this.toDeleted);
  }

  delAllUploads(id){
    this.upload.delAllUploads(id, this.tb);
  }

  popularImg(formData, arquivo) {
    formData.append('arquivo', arquivo);
  }

  del(item, i) {
    this.utils.confirmRemove()
      .then((willDelete) => {
        if (willDelete) {
          this.toDeleted.push(item.IDA001);
          delete this.dataSource.data[i];
          this.dataSource.data = this.dataSource.data.filter(function (el) {
            return el != null;
          });          
        }
      });
  }

  viewItem(item){
    this.dialogRef.open(ModalItem, {
      height: '80%',
      width: '95%',
      disableClose: false,
      data: {
          item: item
      }
    });
  }

  files: File[] = [];
  dropFiles:any
  onSelect(event) {
    if(event.rejectedFiles.length> 0){
      this.utils.msgError("Arquivo incompatível!");
    }
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
}
