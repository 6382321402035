import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';

@Injectable({
    providedIn: 'root'
})
export class UploadComponentService {

    constructor(
        private data: DataService
    ) { }

    getArquivos(id, tb) {
        return this.data.list(`A001,G009/A001.IDA001/*/A001.ID${tb}=${id},A001.USER=G009.IDG009,A001.DELETED=N/A001.*, G009.NOME`);
    }

    delUploads(toDeleted) {
        for (let i = 0; i < toDeleted.length; i++) {
            this.data.del('A001', toDeleted[i], 'IDA001', 'N').subscribe(
                data => {

                }
            )
        }
    }

    delAllUploads(id, tb){
        this.data.del('A001', id, 'ID' + tb, 'N').subscribe(
          data => {
    
          }
        )
      }
}