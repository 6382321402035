import { Component, Inject, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilsService } from '../../shared/services/utils.service';
import { DataService } from '../../shared/services/data.service';
import { GlobalService } from '../../shared/services/global.service';
import { Router } from '@angular/router';

@Component({
    selector: 'fuse-contacts-contact-form-dialog',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FuseContactsContactFormDialogComponent {
    dialogTitle: string;
    form: FormGroup;

    autenticado = false;
    senha: boolean = false;
    perfil: boolean = false;
    password: any;
    arquivo: any;
    @Output() respostaFamilia = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<FuseContactsContactFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private formBuilder: FormBuilder,
        private utils: UtilsService,
        private dataService: DataService,
        public global: GlobalService,
        private route: Router
        ) {
            this.dialogTitle = 'Meu perfil';
        }
    
    ngOnInit(): void {
         this.form = this.formBuilder.group({
            
            password       : ['', Validators.required],
            passwordConfirm: ['', Validators.required]
        }); 

        this.utils.setDisable(this.form);
    }

    onFileSelected(event) {

        //PEGA O ARQUIVO ESCOLHIDO
        let temp = event.target.files[0];
    
        if (temp.type != 'image/jpeg' && temp.type != 'image/png') {
          this.utils.msgError('Somente é permitido imagens no formato JPG ou PNG')
        }
        else {
          if (temp.size > 200000) {
            this.utils.msgError('Tamanho da foto excede o limite permitido de 200KB')
          }
        }

        //FAZ UPLOAD DA NOVA FOTO
        let formData = new FormData();
        formData.append('arquivo',temp);

        this.dataService.storage(formData)
            .subscribe(
                data => {

                    let foto = data;

                    //TROCA O VALOR PARA A FOTO NOVA
                    this.dataService.update('G009', this.global.getUser().IDG009, 'IDG009', { FOTO: data })
                        .subscribe(
                            data => {
                                
                               this.utils.msgSuccess('Foto atualizado com sucesso!');
                               let user = this.global.getUser();
                               user.FOTO = foto;
                               this.global.setUser(user);
                                
                            },
                            error => {
                                this.utils.msgError('Erro ao atualizar!');
                            });
                },
                error => {
                }
            )
    
    }

    confirmaSenha(senhaDigitada)
    {
        let dados =  {
            EMAIL: this.global.getUser().EMAIL,
            PASS: senhaDigitada
        };
        
        this.dataService.validarSenha('G009/EMAIL/PASS', dados)
        .subscribe(
            data => {
                this.autenticado = true;
                this.utils.setEnable(this.form);
            },
            error => {
                this.utils.msgError(error.error);
            }
        )
    }
    salvarSenha()
    {

        if(this.form.valid){

            let senha = this.form.get('password').value;
            let confirmaSenha = this.form.get("passwordConfirm").value;
            
            if(senha != confirmaSenha){
                this.utils.msgError('As Senhas não são iguais!')
                this.form.get('password').reset();
                this.form.get('passwordConfirm').reset();
            }else{
                
                this.global.getUser();
                let dados = this.global.getUser();
                dados.PASS = this.form.get('password').value;
                let rota = `${this.global.getUser().IDG009}/G009/EMAIL`; 
                this.dataService.updateSenha(rota, dados).subscribe(
                    data => {

                        this.senha = false;
                        this.autenticado = false;
                        this.form.reset();
                        this.utils.setDisable(this.form);
                        this.utils.msgSuccess('Senha atualizada com sucesso!');

                    },
                    error => {
                    }
                ) 
            }
           
        }else{
            this.utils.msgError('Ops, houve um erro em sua requisição!');
        }
        
    }

    alterarDados(){
        this.route.navigate(['/alterar-dados']);
        this.dialogRef.close();
    }
}