import { Component, OnInit, ViewChild, ElementRef, Inject, Input } from '@angular/core';

import {
    MatTableDataSource,
    MatSort,
    MatPaginator
} from '@angular/material';

import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DataService } from '../../services/data.service';
import { UtilsService } from '../../services/utils.service';
import { LocaisSelectComponentService } from '../locais-select-component/locais-select-component.service';

export interface FuncionarioData {
    IDG014: string;
    NOME: string;
    CPF: string;
}

@Component({
    selector: 'app-funcionarioslist',
    templateUrl: './funcionarios.component.html',
    styleUrls: ['./funcionarios.component.scss']
})

export class FuncionariosComponentDialog implements OnInit {

    @ViewChild('TABLE') tableexport: ElementRef;

    @Input() valores;
    @Input() valoresModal;
    @Input() edit;
    @Input() observ;

    form: FormGroup;

    funcionarios = [];
    dados: any = [];
    tableView: boolean;
    total: number = 0;

    // TABELAS
    tb = 'G030';
    tbFuncionarios = 'G014';
    tbLocais = 'G004';

    displayedColumnsFuncionarios: string[] = ['NOME', 'CPF', 'VALOR', 'DATA'];
    dataSourceFuncionarios: MatTableDataSource<FuncionarioData>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private formBuilder: FormBuilder,
        private dataService: DataService,
        private utilsService: UtilsService,
        private nav: LocaisSelectComponentService
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            VALOR: [0]
        });

        this.form.get('VALOR').disable();

        this.listFuncionariosLocal(this.nav.getLocalSelected());
    }

    listFuncionariosLocal(id) {
        this.dataService.list(`G014/NOME/asc/IDG014=${id},DELETED=N,STS=A`)
            .subscribe(
                data => {
                    this.funcionarios = data.data;

                    this.setControls();

                    this.dataSourceFuncionarios = new MatTableDataSource(this.funcionarios);
                    if (this.tableView) {
                        this.utilsService.paginatorTranslate(this.paginator);
                    }
                    this.dataSourceFuncionarios.paginator = this.paginator;
                    this.dataSourceFuncionarios.sort = this.sort;

                    this.dataSourceFuncionarios.filterPredicate = function (data, filter: string): boolean {
                        return data.IDG014.toString().includes(filter) ||
                            data.NOME.toLowerCase().includes(filter.toLowerCase()) ||
                            data.CPF.toLowerCase().includes(filter.toLowerCase())
                    };

                    if (this.valores || this.valoresModal) {
                        if (this.valoresModal){
                            this.form.patchValue(this.valoresModal)
                        }
                        else {
                            this.valores.subscribe(
                                data => {
                                    this.form.patchValue(data);
                                }
                            )
                        }
                    }

                    if (!this.edit) {
                        this.form.disable();
                    }
                    else {
                        this.form.enable();
                        this.form.get('VALOR').disable();
                    }
                }
            )
    }

    applyFilterFuncionarios(filterValue: string) {
        this.dataSourceFuncionarios.filter = filterValue.trim().toLowerCase();

        if (this.dataSourceFuncionarios.paginator) {
            this.dataSourceFuncionarios.paginator.firstPage();
        }
    }

    setControls() {
        for (let i = 0; i < this.funcionarios.length; i++) {
            let funcionario = this.funcionarios[i];

            this.form.setControl(
                `VALOR_${funcionario.IDG014}`,
                new FormControl(0)
            );

            this.form.setControl(
                `DATA_${funcionario.IDG014}`,
                new FormControl(null)
            );
        }
    }

    soma() {
        let resultado = 0;
        for (let i = 0; i < this.funcionarios.length; i++) {
            let funcionario = this.funcionarios[i];
            if (this.form.get('VALOR_' + funcionario.IDG014).value != null) {
                resultado = resultado + this.form.get('VALOR_' + funcionario.IDG014).value;
            }
        }

        this.form.get("VALOR").setValue(resultado);

        if (this.observ){
            this.observ.next(resultado);
        }
    }

    getFormValue() {
        return this.form.getRawValue();
    }
}