import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewSharedModule } from 'app/shared/shared.module';

import { IndexDlgMovimentacoesComponent } from './index-dlg-movimentacoes/index-dlg-movimentacoes.component';
import { IndexComponent } from './index/index.component';
import { NewMovimentacoesRoutingModule } from './new-movimentacoes-routing.module';
import { FormDlgMovimentacoesComponent } from './form-dlg-movimentacoes/form-dlg-movimentacoes.component';

@NgModule({
  imports: [CommonModule, NewSharedModule, NewMovimentacoesRoutingModule],
  declarations: [IndexComponent, IndexDlgMovimentacoesComponent, FormDlgMovimentacoesComponent],
  exports: [IndexDlgMovimentacoesComponent],
  entryComponents: [IndexDlgMovimentacoesComponent, FormDlgMovimentacoesComponent],
})
export class NewMovimentacoesModule {}
