import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { UtilsService } from 'app/main/shared/services/utils.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { FormDlgMovimentacoesComponent } from '../form-dlg-movimentacoes/form-dlg-movimentacoes.component';
import { MovimentacoesStoreService } from '../movimentacoes-store/movimentacoes-store.service';

export interface MovimentacoesData {
  NOMECONTA: string;
  VALOR: number;
  DATA: string;
}

@Component({
  selector: 'app-index-dlg-movimentacoes',
  templateUrl: './index-dlg-movimentacoes.component.html',
  styleUrls: ['./index-dlg-movimentacoes.component.scss'],
})
export class IndexDlgMovimentacoesComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  referencia: any;
  id: any;
  edit: any;
  idLocal: any;
  tipoMov: any;

  movimentacoes: any;

  dataSource: MatTableDataSource<MovimentacoesData>;
  displayedColumns: string[] = ['IDG039', 'NOMECONTA', 'VALOR', 'DATA', 'VISUALIZAR'];

  constructor(
    private dialogRef: MatDialogRef<IndexDlgMovimentacoesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private utils: UtilsService,
    private movimentacoesStoreService: MovimentacoesStoreService
  ) {
    this.tipoMov = this.data.tipoMov;
    this.referencia = this.data.referencia;
    this.id = this.data.id;
    this.edit = this.data.edit;
    this.idLocal = this.data.idLocal;

    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    if (this.id) {
      this.getMovimentacoes();
    }
  }

  getMovimentacoes() {
    let payload = {};
    if (this.referencia.IDG019) {
      payload['IDG034'] = this.id;
      payload['tipoMov'] = 'R';
      payload['idMov'] = this.referencia.IDG019;
    } else if (this.referencia.IDG021) {
      payload['IDG034'] = this.id;
      payload['tipoMov'] = 'D';
      payload['idMov'] = this.referencia.IDG021;
    }

    this.subscriptions.push(
      this.movimentacoesStoreService
        .loadMovimentacoesByLancamentoReceitaOrDespesa(payload)
        .pipe(untilDestroyed(this))
        .subscribe(data => {
          this.movimentacoes = data.value;
          this.dataSource = null;

          this.data.data = this.utils.formatTimeZone('DATA', data);

          this.dataSource = new MatTableDataSource(this.movimentacoes);
        })
    );
  }

  dlgFormMovimentacoes(row, edit?) {
    const dialogRef = this.dialog.open(FormDlgMovimentacoesComponent, {
      width: '80%',
      height: 'auto',
      disableClose: false,
      data: {
        tipoMov: this.tipoMov,
        idLocal: this.idLocal,
        id: this.data.id,
        edit: edit ? edit : false,
        movimentacao: row,
        referencia: this.referencia,
        // nomeLocal: this.lancamento.G004.NOME,
        // formControlNameString: formControlNameString,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMovimentacoes();
    });
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
