import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { fuseAnimations } from '../../../../animations';
import { FuseConfigService } from '../../../../services/config.service';

@Component({
  selector: 'fuse-nav-horizontal-collapse',
  templateUrl: './nav-horizontal-collapse.component.html',
  styleUrls: ['./nav-horizontal-collapse.component.scss'],
  animations: fuseAnimations,
})
export class FuseNavHorizontalCollapseComponent implements OnDestroy, AfterViewInit, DoCheck {
  onConfigChanged: Subscription;
  fuseSettings: any;
  isOpen = false;

  @HostBinding('class') classes = 'nav-item nav-collapse';
  @Input() item: any;

  @HostListener('mouseenter')
  open() {
    this.isOpen = true;
  }

  @HostListener('mouseleave')
  close() {
    this.isOpen = false;
  }

  constructor(private fuseConfig: FuseConfigService, private cdRef: ChangeDetectorRef) {
    this.onConfigChanged = this.fuseConfig.onConfigChanged.subscribe(newSettings => {
      this.fuseSettings = newSettings;
    });
  }

  ngAfterViewInit() {
    this.ngDoCheck();
  }
  ngDoCheck() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }
}
