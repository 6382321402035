import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatToolbarModule,
  MatTableModule,
  MatMenuModule,
  MatIconModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatStepperModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatSortModule,
  MatSnackBarModule,
  MatCardModule,
  MatTabsModule,
  MatDialogModule,
  MatExpansionModule,
  MatDividerModule,
  MAT_DATE_LOCALE,
} from '@angular/material';

import { SampleSharedComponentComponent } from './components/sample-shared-component/sample-shared-component.component';
import { DisableControlDirective } from './directives/disableControl.directive';
import { DisableLancamentoDirective } from './directives/disableLancamento.directive';
import { DisableFechadoDirective } from './directives/disableLancamentoFechado.directive';
import { InitDestroyDirective } from './directives/init-destroy.directive';
import { SampleDirectiveDirective } from './directives/sample-directive.directive';
import { SamplePipePipe } from './pipes/sample-pipe.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from 'app/main/shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { RelatoriosService } from 'app/main/shared/services/relatorios.service';
import { WizardStepComponent } from './components/wizard-step/wizard-step.component';

@NgModule({
  declarations: [
    SampleSharedComponentComponent,
    SampleDirectiveDirective,
    InitDestroyDirective,
    DisableLancamentoDirective,
    DisableFechadoDirective,
    DisableControlDirective,
    SamplePipePipe,
    WizardStepComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FuseSharedModule,
    NgxDatatableModule,
    MatPaginatorModule,
    MatTableModule,
    MatChipsModule,
    MatSortModule,
    FormsModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCardModule,
    MatMenuModule,
    CurrencyMaskModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    MatTabsModule,
    SharedModule,
    MatDialogModule,
    MatExpansionModule,
    PdfViewerModule,
    VerticalTimelineModule,
    MatDividerModule,
  ],
  exports: [
    InitDestroyDirective,
    DisableLancamentoDirective,
    SampleDirectiveDirective,
    DisableFechadoDirective,
    DisableControlDirective,
    ReactiveFormsModule,
    MatToolbarModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FuseSharedModule,
    NgxDatatableModule,
    MatPaginatorModule,
    MatTableModule,
    MatChipsModule,
    MatSortModule,
    FormsModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCardModule,
    MatMenuModule,
    CurrencyMaskModule,
    NgxMatSelectSearchModule,
    TextMaskModule,
    MatTabsModule,
    SharedModule,
    MatDialogModule,
    MatExpansionModule,
    PdfViewerModule,
    VerticalTimelineModule,
    MatDividerModule,
    WizardStepComponent,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }],
})
export class NewSharedModule {}
