import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sample-shared-component',
  templateUrl: './sample-shared-component.component.html',
  styleUrls: ['./sample-shared-component.component.scss']
})
export class SampleSharedComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
