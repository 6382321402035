import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'environments/environment';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  isDev = !environment.production;

  constructor(private logger: NGXLogger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isDev) {
      
    }

    return next.handle(request);
  }
}
