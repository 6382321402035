import { Directive, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[ngOnInit],[ngOnDestroy]',
})
export class InitDestroyDirective implements OnInit, OnDestroy {
  @Output('ngOnInit') init = new EventEmitter<void>();
  @Output('ngOnDestroy') destroy = new EventEmitter<void>();

  ngOnInit(): void {
    this.init.emit();
  }
  ngOnDestroy(): void {
    this.destroy.emit();
  }
}
