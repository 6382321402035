import { Component, OnInit, Inject, Input, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatSelect, MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogRef, } from "@angular/material";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { DataService } from "../services/data.service";
import { UtilsService } from "../services/utils.service";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GlobalService } from "../services/global.service";
import { SelectionModel } from "@angular/cdk/collections";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { FiltersService } from "../services/filters.service";



export interface FiltroData {
    NOMEFILTRO: string;
    CAMPO: string;
    OPERADOR: string;
    EXPRESSAO: string;
    IDS002: string;
    CAMPOREAL: string;
    TIPO: string;
}

@Component({
    selector: 'app-filtros-component',
    templateUrl: './filtros.component.html',
    styleUrls: ['./filtros.component.scss']
})

export class FiltrosComponent implements OnInit {

    //viewschild
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // variaveis
    tabela;
    campos: any = [];
    usuario;

    // forms
    horizontalStepperStep1: FormGroup;

    // tabelas
    tb = 'S001';
    tbFiltros = 'S002';
    filtros;
    dados;
    flagCampoDate: boolean = false;

    public camposFilter: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public CAMPOSFILTER: FormControl = new FormControl();

    private _onDestroy = new Subject<void>();

    //grid 
    displayedColumns: string[] = ["select", "NOMEFILTRO", "CAMPO", 'OPERADOR', "EXPRESSAO", "ACOES"];
    dataSource: MatTableDataSource<FiltroData>;
    selection = new SelectionModel<FiltroData>(true, []);

   
    constructor(
        private formBuilder: FormBuilder,
        private dataService: DataService,
        private utils: UtilsService,
        private global: GlobalService,
        public dialogRef: MatDialogRef<FiltroData>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private filters: FiltersService
    ) { }

    ngOnInit() {

        this.horizontalStepperStep1 = this.formBuilder.group({
            NOMEFILTRO: ['', Validators.required],
            CAMPOS: ['', Validators.required],
            OPERACAO: ['', Validators.required],
            EXPRESSAO: ['']
        });

        this.tabela = this.data.tabela;
        this.dados = this.data.dados;
        this.usuario = this.global.getUser().IDG009;

        this.getCampos();
        this.list();

        this.CAMPOSFILTER.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterCampos();
            });



    }

    list() {
        this.dataService.list(`${this.tbFiltros} F, ${this.tb} C/F.IDS002/desc/F.IDS001=C.IDS001,F.IDG009=${this.usuario},F.TABELA=${this.tabela}/F.*,C.TIPO,C.CAMPOREAL,C.CAMPOFANT`)
            .subscribe(
                data => {

                    this.filtros = data.data;
                    
                    this.dataSource = new MatTableDataSource(this.filtros);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                },
                error => {
                    this.utils.msgError('Ops, houve um erro em sua requisição!');
                }
            )
    }

    private filterCampos() {
        this.filters.genericFilter(this.campos, this.CAMPOSFILTER, this.camposFilter, 'CAMPOREAL');
    }

    getCampos() {
        this.dataService.list(`${this.tb}/CAMPOFANT/asc/TABELA=${this.tabela}`)
            .subscribe(
                data => {

                    this.campos = data.data;
                    this.camposFilter.next(this.campos.slice());
                },
                error => {
                    this.utils.msgError("Ops, houve um erro em sua requisição!");
                }
            )
    }

    salvar() {

        if(this.horizontalStepperStep1.valid){
            let campos = this.horizontalStepperStep1.value;
            let dados;
    
            dados = {
                'TABELA': this.tabela,
                'IDG009': this.usuario,
                'NOMEFILTRO': campos.NOMEFILTRO.toUpperCase(),
                'IDS001': campos.CAMPOS,
                'OPERADOR': campos.OPERACAO.toUpperCase(),
                'EXPRESSAO': campos.EXPRESSAO.toUpperCase(),
            }
    
            this.dataService.add(this.tbFiltros, dados)
                .subscribe(
                    data => {
                        
                        this.horizontalStepperStep1.reset();
                        this.list();
                    },
                    error => {
                        this.utils.msgError('Ops, houve um erro em sua requisição!');
                    }
                )
        }else{
            this.utils.msgError('Preencha os campos de filtro');
        }
       
    }

    isAllSelected() {

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
 

        return numSelected === numRows;

    }


    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));


    }

    salvarFavorito() {

        let filtro = this.selection.selected;
       
        if (filtro.length > 0 && filtro.length == 1) {

            let campoReal = filtro[0].CAMPOREAL;
            let operador = filtro[0].OPERADOR;
            let expressao = filtro[0].EXPRESSAO;
            let tipo  = filtro[0].TIPO

            let dados = {
                tabela: this.tabela,
                user: this.global.getUser().IDG009,
                filtro: filtro[0].IDS002
            }
           

            this.dataService.favoritos(dados)
                .subscribe(
                    data => {

                        this.utils.msgSuccess("Salvo com sucesso");
                        this.listFiltro(campoReal, operador, expressao, tipo);

                    },
                    error => {
                        this.utils.msgError('Ops, houve um erro em sua requisição');
                    }
                )
        }else{
            this.selection.clear();
            this.utils.msgError('Só pode selecionar um favorito!')
        }
        
    }

    filtrar(){
        
        let campoReal = this.horizontalStepperStep1.get('CAMPOS').value;
        let operador = this.horizontalStepperStep1.get('OPERACAO').value;
        let expressao = this.horizontalStepperStep1.get('EXPRESSAO').value.toUpperCase();
        
        this.dataService.getItem('S001', campoReal, 'IDS001')
            .subscribe(
                data => {
                    campoReal = data.data[0].CAMPOREAL;
                    let tipo = data.data[0].TIPO;

                    this.listFiltro(campoReal, operador, expressao, tipo);
                },
                error => {
                    this.utils.msgError('Ops, houve um erro em sua requisição');
                }
            )
       
    }

    listFiltro(campoReal, operador, expressao, tipo) {
       
        let filter = [];

        if(tipo == 'date'){
            this.dados = this.utils.formatTimeZone(campoReal, this.dados);
        }
   

        if (operador === "IGUAL A") {
            filter = this.dados.filter(d => { return d[campoReal] == expressao });
        }

        if(operador === "MAIOR QUE"){
            filter = this.dados.filter(d => { return d[campoReal] > expressao });
        }

        if(operador === "MENOR QUE"){
            filter = this.dados.filter(d => { return d[campoReal] < expressao });
        }

        if(operador === "MENOR OU IGUAL QUE"){
            filter = this.dados.filter(d => { return d[campoReal] <= expressao });
        }

        if(operador === "MAIOR OU IGUAL A"){
            filter = this.dados.filter(d => { return d[campoReal] >= expressao });
        }

        if(operador === "DIFERENTE DE"){
            filter = this.dados.filter(d => { return d[campoReal] != expressao });
        }
        
        if(operador === "CONTÉM A EXPRESSÃO"){
            filter = this.dados.filter(d => { return d[campoReal].includes(expressao) });
        }

        if(operador === "NÃO CONTÉM A EXPRESSÃO"){
            filter = this.dados.filter(d => { return d[campoReal].includes(expressao) != true });
        }

        if(operador === "DIFERENTE DE VAZIO"){
            filter = this.dados.filter(d => { return d[campoReal] != ""  });
        }

        

        this.dialogRef.close(filter)
    }

    del(row){

        this.utils.confirmRemove()
            .then((willDelete) => {

                if(willDelete){
                    this.dataService.del('S002', row.IDS002, 'IDS002', 'S')
                    .subscribe(
                        data => {
                            this.utils.msgError('Excluído com sucesso!');
                            this.list();
                        },
                        error => {
                            this.utils.msgError('Ops, houve um erro em sua requisição');
                        }
                    )
                }
            })
        
    }

    desmarcarFavorito(id){
        this.dataService.update('S002', id, 'IDS002', { 'FAVORITO': 0 })
            .subscribe(
                data => {
                    this.utils.msgSuccess('Favorito foi desmarcado');
                    this.list();
                },
                error => {
                    this.utils.msgError('Ops, houve um erro em sua requisição!');
                }
            )
    }

    verificaTipoCampo(campo){
        this.dataService.getItem('S001', campo, 'IDS001')
            .subscribe(
                data => {
                  
                  if(data.data[0].TIPO == 'date' || data.data[0].TIPO == 'datetime'){
                    this.flagCampoDate = true ;
                  }else{
                    this.flagCampoDate = false;
                  }

                },
                error => {
                    this.utils.msgError('Ops, houve um erro em sua requisição!');
                }
            )
    }

}