import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class FiltersService {

    constructor() {
    }   
    
    genericFilter(arr, control, filter, compare, compare2?, compare3?, compare4?) {
        if (!arr) {
            return;
        }
        // get the search keyword
        let search = control.value;
       
        if (!search) {
            filter.next(arr.slice());
            return;
        } else {
            search = search.toLowerCase();
        }

        filter.next(
            arr.filter(
                item => 
                    (item[compare]) ? item[compare].toString().toLowerCase().includes(search) : '' ||
                    (!compare2 ? false : item[compare2] ? item[compare2].toString().toLowerCase().includes(search) : false) || 
                    (!compare3 ? false : item[compare3] ? item[compare3].toString().toLowerCase().includes(search) : false) ||
                    (!compare4 ? false : item[compare4] ? item[compare4].toString().toLowerCase().includes(search) : false)
            )
        );
    }
}
