import { DataService } from 'app/main/shared/services/data.service';
import { GlobalService } from 'app/main/shared/services/global.service';
import { Subject } from 'rxjs';
import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';

import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { FuseMatchMediaService } from '../../services/match-media.service';
import { FuseNavigationService } from '../navigation/navigation.service';
import { FuseConfigService } from '../../services/config.service';
import { MatMenu, MatMenuTrigger } from '@angular/material';

@Component({
    selector: 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, OnDestroy {
    shortcutItems;
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching = false;
    mobileShortcutsPanelActive = false;
    toolbarColor: string;
    matchMediaSubscription: Subscription;
    onConfigChanged: Subscription;

    allMenus;

    @ViewChild('searchInput') searchInputField;
    @ViewChild('shortcuts') shortcutsEl: ElementRef;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    constructor(
        private renderer: Renderer2,
        private observableMedia: ObservableMedia,
        private fuseMatchMedia: FuseMatchMediaService,
        private fuseConfig: FuseConfigService,
        private global: GlobalService,
        private data: DataService,
        private fuseNavigationService: FuseNavigationService
    ) {
        this.onConfigChanged =
            this.fuseConfig.onConfigChanged
                .subscribe(
                    (newSettings) => {
                        this.toolbarColor = newSettings.colorClasses.toolbar;
                    }
                );
    }

    ngOnInit() {
        this.shortcutItems = [];

        if (this.global.getUser()) {
            this.getFavoritos().subscribe(
                data => {
                    this.shortcutItems = data;
                }
            )

            this.getMenus().subscribe(
                data => {
                    this.filteredNavigationItems = this.navigationItems = this.fuseNavigationService.getFlatNavigation(data);
                }
            )

            this.getAllMenus().subscribe(
                data => {
                    this.allMenus = data;
                }
            )

            this.matchMediaSubscription =
                this.fuseMatchMedia.onMediaChange.subscribe(() => {
                    if (this.observableMedia.isActive('gt-sm')) {
                        this.hideMobileShortcutsPanel();
                    }
                });
        }
    }

    getFavoritos() {
        let observ = new Subject();

        this.data.list(`G032,G043,G031,G033/G032.title/asc/G031.IDG031=${this.global.getUser().IDG031},G033.IDG031=G031.IDG031,G032.IDG032=G033.IDG032,G043.IDG009=${this.global.getUser().IDG009},G043.IDG032=G032.IDG032,G032.TYPE=2,G032.DELETED=N,G032.STS=A/G032.TITLE AS title, G032.TYPE as type, G032.ICON as icon, G032.URL as url, G043.IDG043`).subscribe(
            data => {
                for (let i = 0; i < data.data.length; i++) {
                    data.data[i].type = 'nav-item'
                }

                observ.next(data.data);
            }
        )
        return observ.asObservable();
    }

    getMenus() {
        let observ = new Subject();

        this.data.list(`G032,G031,G033/G032.title/asc/G031.IDG031=${this.global.getUser().IDG031},G033.IDG031=G031.IDG031,G032.IDG032=G033.IDG032,G032.TYPE=2,G032.DELETED=N,G032.STS=A`).subscribe(
            data => {
                for (let i = 0; i < data.data.length; i++) {
                    data.data[i].type = 'item'
                }

                observ.next(data.data);
            }
        )
        return observ.asObservable();
    }

    getAllMenus() {
        let observ = new Subject();

        this.data.list('G032/title/asc/TYPE=2,DELETED=N,STS=A').subscribe(
            data => {
                observ.next(data.data);
            }
        )
        return observ.asObservable();
    }

    ngOnDestroy() {
        if (this.matchMediaSubscription){
            this.matchMediaSubscription.unsubscribe();
        }
    }

    search(event) {
        const value = event.target.value.toLowerCase();

        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        } else {
            this.filteredNavigationItems = [];
            this.searching = true;
            this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
                return navigationItem.title.toLowerCase().includes(value);
            });

        }
        
    }

    toggleShortcut(event, itemToToggle) {
        event.stopPropagation();

        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.data.del('G043', this.shortcutItems[i].IDG043, 'IDG043', 'S').subscribe(
                    data => {
                        this.shortcutItems.splice(i, 1);
                        let input: any = document.getElementById('search');
                        input.value = '';
                    }
                )
                return;
            }
        }

        let index = this.allMenus.findIndex(val => itemToToggle.url == val.url);
        let item = this.allMenus[index];

        let dados = {
            IDG009: this.global.getUser().IDG009,
            IDG032: item.IDG032
        }

        this.data.add('G043', dados).subscribe(
            data => {
                this.shortcutItems.push(itemToToggle);
                let input: any = document.getElementById('search');
                input.value = null;
                this.trigger.closeMenu();
            }
        )
    }

    isInShortcuts(navigationItem) {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    onMenuOpen() {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    showMobileShortcutsPanel() {
        this.mobileShortcutsPanelActive = true;
        this.renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    hideMobileShortcutsPanel() {
        this.mobileShortcutsPanelActive = false;
        this.renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
