import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NotFound404Component } from './components/not-found404/not-found404.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once.guard';
import { HttpErrorInterceptor } from './interceptor/http-error.interceptor';
import { HttpTokenInterceptor } from './interceptor/http-token.interceptor';
import { ProjectLayoutComponent } from './layouts/project-layout/project-layout.component';
import { BaseService } from './services/base.service';
import { UtilityService } from './utilities/utility.service';

@NgModule({
  declarations: [ProjectLayoutComponent, NotFound404Component, HeaderComponent, FooterComponent, ScrollToTopComponent],
  imports: [CommonModule, RouterModule, HttpClientModule],
  exports: [HttpClientModule],
  providers: [
    BaseService,
    UtilityService,
    // { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },

    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
