import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatProgressBarModule, MatToolbarModule, MatSelectModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { FuseToolbarComponent } from './toolbar.component';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';

import { FuseContactsContactFormDialogComponent } from './contact-form/contact-form.component';
import { FuseConfirmDialogModule } from '@fuse/components';

import { MatCheckboxModule, MatNativeDateModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatSidenavModule, MatTableModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';



@NgModule({
    declarations: [
        FuseToolbarComponent,
        FuseContactsContactFormDialogComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSidenavModule,
        MatTableModule,
        MatNativeDateModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        FuseConfirmDialogModule,
        MatSelectModule,
        NgxMatSelectSearchModule
    ],
    exports     : [
        FuseToolbarComponent
    ],
    entryComponents: [FuseContactsContactFormDialogComponent]
})
export class FuseToolbarModule
{
}
